:root {
	--header: rgba(15, 15, 15, .7);
	--background: #0f0f0f;
	--text: #989898;
	--highlight: #a63d40;
	--highlight-2: #53f4ff;
	--sidebar: #4059ad;
	--sidebar-text: #f0f0f0;
	--monofont: monospace;
	--body-font: Arial, Helvetica, sans-serif;

	--guesses-text: "Gjett: ";
	--precision-text: "Nøyaktighet: ";
}

* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}
body {
	font-family: var(--body-font);
	background-color: var(--background);
    color: var(--text);
	margin: 0;
	padding: 0;
}

a {
	color: var(--sidebar-text);
}

button.link {
	font-family: var(--body-font);
	font-size: 1rem;
	background: transparent;
	border: none;
	display: inline;
	color: var(--sidebar-text);
	text-decoration: underline;
	padding: 0;

}

header {
	font-family: var(--monofont);
	color: var(--highlight-2);
	background: var(--header);
	padding: .5rem;
	position: sticky;
	top: 0;
	border-bottom: solid 1px var(--text);
}

header h1 {
	font-size: 2rem;
	margin: 0;
}

.body-container {
	margin: 0;
	padding: 1rem;
	font-family: var(--monofont);
	font-size: 1.125rem;
	line-height: 1.6875rem;
	margin-bottom: 40vh;
	
	max-width: 100%;
	overflow-x: auto;
}

.body-container h1, .body-container h2 {
	font-size: 1.6875rem;
	line-height: 2.53125rem;
}

.body-container h3 {
	font-size: 1.31625rem;
	line-height: 1.974375rem;;
}

.body-container h4, .body-container h5, .body-container h6 {
	font-size: 1.125rem;
	line-height: 1.6875rem;
}

.body-container table {
	table-layout: fixed;
}

.black-box {
	background-color: #989898;
}
.active-word {
	background-color: var(--highlight);
	scroll-margin-top: 30vh;
}

.selected-active-word {
	background: var(--highlight-2);
	color: var(--background);
}

.guess-box {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	color: var(--sidebar-text);
	padding: .5rem;
	background: var(--sidebar);
	max-height: 35vh;
	overflow-y: auto;
	overflow-x: hidden;
	border-top: solid 1px var(--text);
	box-shadow: 0 -1rem 1rem var(--text);
}

.guess-form {
	display: flex;
	max-width: 100%;
}
.guess-form input, .guess-form button {
	display: block;
	margin: .25rem;
	font-size: 1rem;
	padding: .5rem;
	border: solid 1px var(--text);
}

.guess-form-submit {
	color: var(--sidebar-text);
	background-color: var(--highlight);
}

.guess-form-up {
	background: var(--sidebar);
	color: var(--sidebar-text);
	border-color: var(--sidebar-text);
	padding: .5rem .25rem;
}

.guess-form-input {
	flex-grow: 1;
	background: var(--background);
	color: var(--text);
	font-family: var(--monofont);
}

.hint-button button {
	display: block;
	margin: .25rem auto;
	font-size: 1rem;
	padding: .5rem;
	border: solid 1px var(--text);

}

.guesses-list {
	width: 100%;
}

.guesses-list td {
	padding: .25rem;
}

.guesses-list .number {
	width: 15%;
}

.guesses-list .word {
	max-width: 0;
	width: 70%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.guesses-list .selected-word {
	color: var(--highlight-2);
}

.guesses-list .count {
	width: 15%;
	text-align: right;
}

.main-menu ul {
	list-style-type: none;
	margin: 1rem 0 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.main-menu button {
	white-space: nowrap;
	border: none;
	text-decoration: underline;
	background: transparent;
	color: var(--sidebar-text);
	font-size: .825rem;
}

.old-info {
	margin: 1rem 1rem;
	color: var(--text);	
	padding: 0;
	text-align: center;
}

.infobox {
	margin: 1rem 1rem 2rem;
	color: var(--text);	
	padding: 0 0 2rem;
	border-bottom: dotted 1px var(--text);

	max-width: 100%;
	overflow-x: auto;
}

.infobox button {
	color: var(--sidebar-text);
	background-color: var(--highlight);
	font-size: 1rem;
	padding: .5rem 1rem;
}

.game-history {
	border-collapse: collapse;
	width: 100%;
}
.game-history th {
	text-align: center;
	color: var(--background);
	background: var(--text);
	padding: .5rem 1rem;
}
.game-history td {
	text-align: center;
	padding: .5rem 1rem;
	border-bottom: dotted 1px var(--text);
}

.game-history th.gametitle, .game-history td.gametitle {
	text-align: left;
}

.game-history td.gametitle {
	font-family: var(--monofont);
	font-size: 1.125rem;
}

.game-history td.gametitle button {
	font-family: var(--monofont);
	font-size: 1.125rem;
	background: transparent;
	border: none;
	display: inline;
	color: var(--sidebar-text);
	padding: 0;
}

.statistics {
	display: grid;
	grid-template-columns: 1fr 1fr;
	text-align: center;
	margin: 1rem 0;
	gap: 1rem;
}

.statistics h2 {
	font-size: 1rem;
	margin: .5rem;
}

.statistics div p {
	font-size: 2.5rem;
	margin: .5rem;
	color: var(--highlight-2);
}

.statistics h2, .statistics p {
	white-space: nowrap;
}

@media screen and (max-width: 1000px) {
	.game-history tr {
		display: block;
		margin-bottom: 1rem;
		border: solid 1px var(--text);
	}
	.game-history tr:first-child {
		display: none;
	}
	.game-history th {
		display: none;
	}
	.game-history td {
		display: block;
	}
	.game-history td.game-id::before {
		content: "#";
	}
	.game-history td.game-id::after {
		content: ":";
	}
	.game-history td.gametitle {
		text-align: center;
	}
	.game-history td.empty {
		display: none;
	}
	.game-history td.game-guesses::before {
		content: var(--guesses-text);
	}
	.game-history td.game-precision::before {
		content: var(--precision-text);
	}
	.game-history td:last-child {
		border-bottom: none;
	}
}

@media screen and (min-width: 1000px) {
	.infobox, .old-info, .body-container, .statistics {
		width: 65vw;
	}
	.statistics {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.guess-box {
		left: initial;
		top: 0;
		max-height: initial;
		width: 30vw;
		border-top: none;
		border-left: solid 1px var(--text);
		box-shadow: -.5rem 0 .5rem var(--text);
	}
}
